//产品详情
<template>
  <div class="content">
    <div class="imgbox">
      <img src="../assets/img/product.png" alt="" />
      <div>
        <p class="tils" v-show="lang == '0'">{{products.tit}}</p>
        <p class="tils" v-show="lang == '1'">{{products.titEn}}</p>
        <p class="cont" v-show="lang == '0'">{{products.cont}}</p>
        <p class="cont" v-show="lang == '1'">{{products.contEn}}</p>
        <el-button v-show="lang == '0'"  @click="$router.push('/contact')">咨询我们<i class="el-icon-right" style="font-size:14px"></i></el-button>
        <el-button v-show="lang == '1'">Consult us<i class="el-icon-right" style="font-size:14px"></i></el-button>
      </div>
    </div>
    <div class="bai">
        <div class="main">
            <div class="title">
                <span></span>
                <span v-show="lang == '0'">产品介绍</span>
                <span v-show="lang == '1'">Product introduction</span>
            </div>
            <div class="box" v-for="(item,index) in pis" :key="index">
                <div class="tils" v-show="lang == '0'">{{item.tit}}</div>
                <div class="tils" v-show="lang == '1'">{{item.titEn}}</div>
                <p v-show="lang == '0'">{{item.cont}}</p>
                <p v-show="lang == '1'">{{item.contEn}}</p>
                <img :src="imgUrl + item.img" alt="">
            </div>
        </div>
    </div>
    <!-- <div class="main">
        <div class="box">
            <div class="tils">产品概述</div>
            <p>雨情自动遥测系统是基于GIS地图开发，能够在线显示、统计、上传降雨量的自动化遥测平台，实时采集降雨量数据，广泛应用于防汛、气象、城市排涝等工作。</p>
            <img src="../assets/img/u43.png" alt="">
        </div>
    </div> -->
    <div class="bai">
        <div class="main">
        <div class="title">
            <span></span>
            <span v-show="lang == '0'">应用场景</span>
            <span v-show="lang == '1'">Application scenario</span>
        </div>
        <div class="box-data">
            <div class="data" v-for="(item,index) in papps" :key="index">
                <div class="top">
                    <img :src="imgUrl + item.img" alt="">
                    <div v-show="lang == '0'">{{item.tit}}</div>
                    <div v-show="lang == '1'">{{item.titEn}}</div>
                </div>
                <div class="bot">
                    <p v-show="lang == '0'">{{item.cont}}</p>
                    <p v-show="lang == '1'">{{item.contEn}}</p>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="main">
      <div class="title">
        <span></span>
        <span v-show="lang == '0'">产品优势</span>
        <span v-show="lang == '1'">Product advantages</span>
      </div>
      <div class="box-product">
          <div class="data" v-for="(item,index) in pas" :key="index">
              <div class="left">
                <img :src="imgUrl + item.img" alt="">
              </div>
              <div class="right">
                  <div v-show="lang == '0'">{{item.tit}}</div>
                  <div v-show="lang == '1'">{{item.titEn}}</div>
                  <p v-show="lang == '0'">{{item.cont}}</p>
                  <p v-show="lang == '1'">{{item.contEn}}</p>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      imgUrl: "http://101.200.43.114:8710/jeecg-boot/sys/common/static/",
      lang: "",
      pis:[],//产品介绍 
      products:{},//产品主表
      papps:[],//产品应用场景
      pas:[],//产品优势
      tit:'',
      titEn:'',
      cont:'',
      contEn:''
    }
  },
  mounted(){
    this.lang = localStorage.getItem("newv");
    this.product()
  },
  methods:{
    product(){
       this.$http.get('/jeecg-boot/xrcx/products/queryById?id='+this.$route.query.id
       ).then(res=>{
         this.products = res.result.products
         res.result.pis.forEach(v => {
           if(v.isdisable == 0){
             this.pis.push(v)
           }
         });
         res.result.papps.forEach(v=>{
           if(v.isdisable == 0){
             this.papps.push(v)
           }
         })
         res.result.pas.forEach(v=>{
           if(v.isdisable == 0){
             this.pas.push(v)
           }
         })
       })
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  // height: 500px;
  .imgbox {
    width: 100%;
    height: 350px;
    position: relative;
    // margin-bottom: 50px;
    // background-image: url("../assets/img/product.png");
    // background-size: 100% 100%;
    div{
      width: 50%;
      height: 80%;
      // background-color: pink;
      position: absolute;
      left: 15%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      .tils{
        font-size: 48px;
        color: #ffffffe5;
      }
      .cont{
        font-size: 14px;
        color: #ffffffe5;
      }
    }
    img{
      width: 100%;
      height: 100%;
    }
    button {
      width: 150px;
      height: 36px;
      color: #fff;
      background-color: rgba(255,255,255,0);
      border: 1px solid #fff;
    }
  }
  .bai {
    width: 100%;
    background-color: #fff;
    padding: 50px 0;
  }
  .main {
    width: 70%;
    // height: 640px;
    margin: 0 auto 50px;
    // background-color: #fff;
    .title {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      span:nth-child(1) {
        display: inline-block;
        width: 8px;
        height: 26px;
        background-color: #409eff;
      }
      span:nth-child(2) {
        font-size: 20px;
        color: #666666;
        font-weight: 700;
        margin-left: 10px;
      }
      span:nth-child(3) {
        font-size: 20px;
        color: #666666;
        font-weight: 700;
        margin-left: 10px;
      }
    }
    .box {
      width: 100%;
      height: 90%;
      // display: flex;
      // justify-content: center;
      // flex-wrap: wrap;
      .tils {
        font-size: 36px;
        color: #333333;
        margin: 50px 0;
      }
      p {
        font-size: 16px;
        color: #7f7f7f;
        margin-bottom: 50px;
      }
      img {
        width: 100%;
        height: 580px;
      }

      
    }
    .box-data{
        width: 100%;
      height: 90%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .data {
        width: 32%;
        height: 340px;
        background-color: #fff;
        box-shadow: 0px 3px 3px 3px #eee;
        margin: 0 16px 16px 0;
        .top {
          width: 100%;
          height: 70%;
          // background-image: url("../assets/img/u54.png");
          display: flex;
          align-items: center;
          position: relative;
          img{
            position: absolute;
            width: 100%;
            height: 100%;
          }
          div {
            width: 100%;
            height: 70px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 28px;
            color: #fff;
            font-weight: 800;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
          }
        }
        .bot{
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                width: 80%;
                height: 80%;
                font-size: 14px;
                color: #999;
            margin-bottom: 0;

            }
        }
      }
    }
    .box-product{
       width: 100%;
      height: 90%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .data{
          width: 100%;
          height: 100px;
          display: flex;
          background-color: #fff;
        //   justify-content: space-between;
        margin-bottom: 20px;
          .left{
              width: 100px;
              height: 100%;
              background-color: #1891ff;
              display: flex;
              justify-content: center;
              align-items: center;
              // background-image: url('../assets/icon/4_1.png');
              img{
                width: 100%;
                height: 100%;
              }
          }
          .right{
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content:space-evenly ;
                  align-items: center;
            div{
                width: 90%;
                display: flex;
                justify-content: flex-start;
                font-size: 20px;
                color: #333333;
            }
            p{
                width: 90%;
                display: flex;
                justify-content: flex-start;
                font-size: 14px;
                color: #999999;
            }
          }
      }
    }
  }
}
</style>